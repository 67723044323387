@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Source+Code+Pro&display=swap');



.App {
  font-family: 'Montserrat', 'Cinzel', serif;
  color: #f9e8d2;
}
h1, h2, h3, h4, h5{
  text-shadow: 5px 5px 3px #0e0c0aa1;
}