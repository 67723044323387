.footer
{
    background-image: linear-gradient(#2c2925,#9b7e52);
    height: 11.6rem;
    align-items: center;
    padding: 0 3rem;
}
#react-item
{
    color:#36dff8;
}
/*reset a properties*/
.footer a:link, .footer a:visited, .footer a:hover, .footer a:active
{
    text-decoration: none;
    color:#f9e8d2;
}
.footer a:hover
{
    font-size: large;
}
.footer h4
{
    text-shadow: 5px 5px 3px #2c2925a1;
}

@media screen and (max-width: 768px)
{
    *{
        font-size: calc((100vw/1000)*16);
    }
}
